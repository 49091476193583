var filterBox__more = document.querySelectorAll(".filterBox__more ul");
var more = document.querySelectorAll(".filtersMoreBTN");
var filterTrigger = false;

more.forEach(function (item, i) {
    item.addEventListener("click", function () {
        filterBox__more[i].classList.toggle("expand");
        console.log(filterBox__more);
        console.log(filterTrigger);

        filterTrigger = !filterTrigger;

        if (filterTrigger) {
            item.innerHTML = "Свернуть";
        } else {
            item.innerHTML = "Еще";
        }
    });
});
document.addEventListener("DOMContentLoaded", () => {
    setTimeout(() => {
        let filterListCompress = document.querySelectorAll(".folded");
        filterListCompress.forEach(function (item) {
            item.style.height =
                item.querySelectorAll("li")[0].getBoundingClientRect().height +
                parseInt(
                    window.getComputedStyle(item.querySelectorAll("li")[0])
                        .marginBottom
                ) +
                item.querySelectorAll("li")[1].getBoundingClientRect().height +
                parseInt(
                    window.getComputedStyle(item.querySelectorAll("li")[1])
                        .marginBottom
                ) +
                "px";
        });
    }, 500);
});

////// DELETE ZERO PRICE /////////////////////////////////////////////////////////////

document.querySelectorAll(".filterBox__price input").forEach(function (item) {
    item.addEventListener("focus", function () {
        if (item.value <= 0) {
            item.value = "";
        }
    });
    item.addEventListener("blur", function () {
        if (item.value == "" || item.value <= 0) {
            item.value = 0;
        }
    });
});

///////////////////// Submit btn scroll ///////////////////////////
var fitersAgree = document.querySelector(".filterBox__btns");
var fitersBox = document.querySelector(".filders");
var searchBlock = document
    .querySelector(".searchWork")
    .getBoundingClientRect().height;
var headerBlock = document
    .querySelector("header")
    .getBoundingClientRect().height;

window.onscroll = function () {
    if (fitersBox.getBoundingClientRect().top < 0) {
        fitersAgree.style.background = "#fff";
        fitersAgree.classList.add("filterBox__btns__open");
        fitersAgree.style.width =
            fitersBox.getBoundingClientRect().width + "px";
    } else {
        fitersAgree.classList.remove("filterBox__btns__open");
        fitersAgree.style.width = "unset";
    }
    if (fitersBox.getBoundingClientRect().bottom < 0) {
        fitersAgree.classList.remove("filterBox__btns__open");
        fitersAgree.style.width = "unset";
    }
};

///////////////////// Mobil open filters ///////////////////////////
var vacancyAllBox = document.querySelector(
    ".lastVacancies .dropContent__small"
);
var vacancyAllBTN = document.querySelector(".filterBox__mobil");

vacancyAllBTN.addEventListener("click", function () {
    vacancyAllBox.classList.toggle("dropContent__small__open");
});
